@media (min-width: 0px){
    .schedule-sortable {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: 600px){
    .schedule-sortable {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (min-width: 900px) {
    .schedule-sortable {
        grid-template-columns: repeat(4, 1fr);
    }
}
@media (min-width: 1200px) {
    .schedule-sortable {
        grid-template-columns: repeat(5, 1fr);
    }
}
@media (min-width: 1536px) {
    .schedule-sortable {
        grid-template-columns: repeat(6, 1fr);
    }
}
.schedule-sortable {
    display: grid;
    gap: 24px;

}
.igo-image-witout-radius .content-image-general .wrapper{
    border-radius: 0px !important;
}
.drag-drop-info {
    position: absolute;
    top: 50%;
    margin-top: -120px;
    width: 85% !important;
}
.content-card .lazy-load-image-background {
    background-color: #000000 !important;
}
.content-card .lazy-load-image-background {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.content-card img {
    border-radius: 0px !important;
}
.content-preview {
    z-index: 9999;
    top: 0px;
    left: 0px;
    overflow: hidden;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 100%;
    width: 100%;
    background-color: rgb(0, 0, 0);
}
.content-preview-buttons {
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 5px;
    background-color: rgb(0, 0, 0);
    opacity: 0.5;
    color: #ffffff;
    width: 100%;
}
.content-preview-buttons button {
    float: right;
}
